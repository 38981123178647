<template>
    <div class='content_pages-page'>
        <h4 class='page-title'>{{ getActionName }}</h4>
        <div class='widget'>
            <FormBuilder
                class='row'
                v-if='pageType === "edit" ? pageData : true'
                :form-data='formData'
                :save='saveDataFunc'
            />
        </div>
    </div>
</template>

<script>
import FormBuilder from '@/components/FormBuilder/FormBuilder';

export default {
    name: 'BlogArticleCreate',
    components: { FormBuilder },
    data() {
        return {
            pageType: null,
            pageData: null,
            saveDataFunc: null,
            formData: {
                'slug': {
                    'type': 'text',
                    'label': 'Slug (Не менять!)',
                    'validation': 'max:30',
                    'value': null,
                },
                'title': {
                    'type': 'text',
                    'label': 'Название категории',
                    'validation': 'required|max:100',
                    'value': null,
                },
            },
        };
    },
    computed: {
        getActionName() {
            if (this.pageType === 'create') {
                return 'Создать категорию блога';
            }

            if (this.pageType === 'edit') {
                return 'Редактировать категорию блога';
            }
            return '';
        },
    },
    methods: {
        extractPageType() {
            const path = this.$route.path.split('/');
            this.pageType = path[path.length - 1];
        },
        async saveNewData(formData) {
            try {
                await this.$axios.post('/crm/blog-categories/', formData);
                this.$toasted.success(`Категория блога ${formData.title} создана!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        handleAxiosError(error) {
            const {data} = error.response;
            if (data.error) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
            if (data.errors) {
                this.$toasted.error(data.message, {position: 'bottom-right'});
                return;
            }
        },
        async saveEditData(formData) {
            try {
                const id = this.$route.params.id;
                await this.$axios.patch('/crm/blog-categories/' + id, formData);
                this.$toasted.success(`Категория блога ${formData.title} отредактирована!`, {position: "bottom-right"});
            } catch (e) {
                this.handleAxiosError(e);
            }
        },
        fillFormData(data) {
            for (const key in this.formData) {
                this.formData[key]['value'] = data[key]
            }
        },
        fillSelectFields(data) {
            for (const key in data) {
                this.formData[key]['options'] = data[key];
                if (this.pageType === 'create') {
                    this.formData[key]['value'] = data[key][0].value;
                }
            }
        },
        async fetchEditData() {
            const id = this.$route.params.id;
            if (!id) {
                this.$toasted.error("Нельзя редактировать без ID", {position: "bottom-right"});
                return;
            }
            try {
                const { data } = await this.$axios.get('/crm/blog-categories/' + id);
                if (data) {
                    if (data.fields) {
                        this.fillSelectFields(data.fields);
                    }
                    if (data.data) {
                        this.fillFormData(data.data);
                        this.pageData = data.data;
                    }
                }
            } catch (e) {
                alert(e);
            }
        },
        async fetchCreateData() {
            try {
                const { data } = await this.$axios.get('/crm/blog-categories/create');
                if (data && data.fields) {
                    this.fillSelectFields(data.fields);
                }
            } catch (e) {
                alert(e);
            }
        },
    },
    async created() {
        this.extractPageType();
        if (this.pageType === 'edit') {
            await this.fetchEditData();
            this.saveDataFunc = this.saveEditData;
        }
        if (this.pageType === 'create') {
            await this.fetchCreateData();
            this.saveDataFunc = this.saveNewData;
        }
    },
};
</script>

<style scoped>

</style>
